.gallary{
    margin: 30px 0;
}
.gallary h2{
    text-align: center;
    font-weight: 700;
    margin:30px 0;
}
.gallary .imgDiv{
    overflow: hidden;
    border-radius: 20px;
    height: 400px;
    margin-bottom: 20px;
    background-size: cover;
    background-position: center;
}
.gallary .imgDiv img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.pagination{
    width: 100%;
    border-top: thin solid #eee;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;

}
.pagination .btn{
    background-color: transparent;
    color: var(--text);
    font-size: 11px;
    border: 0;
    padding:  10px;
}   
.pagination .btn:hover{
    background-color:#000;
    color: #fff;
}
.pagination .btn svg{
    font-size: 12px;
}
.pagination ul{
    padding-left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    height: 100%;
    margin-top: 10px;
}
.pagination ul li{
    width: 30px;
    height: 30px;
    padding: 5px;
    box-sizing: border-box;
    border-radius:5px;
    font-size: 12px;
    display: grid;
    margin: 2px;
    place-items: center;
    transition: 0.5s;
}
.pagination ul li:hover{
   border:thin solid #000;
    color: #000;
    cursor: pointer;
}
.pagination ul li.active{
    color: #fff;
    background-color: #000;
}