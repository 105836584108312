.vedio_section {
  width: 100%;
  height: 500px;
  margin: 20px 0;
  background: url("./../images/vedio.gif");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.vedio_section .vedio-overlay {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
}
