.navbarTop{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
}
.navbarTop .burger .burger_icon{
    width: 30px;
    height: 30px;
    background-color:#fff;
    border: thin solid #f2f2f2;
    border-radius: 100px;
    display: grid;
    place-items: center;
    cursor: pointer;
}
.navbarTop .burger .burger_icon svg{
    color: #000;
}
.navbarTop  .icons svg {
    font-size: 22px;
    margin-right: 10px;
    color: #000;
}

@media (max-width:576px) {
    .navbarTop .logo img{
        width: 90%;
    }

    .navbarTop  .icons svg {
        font-size: 16px;
        margin-right: 10px;
    }
}