.footer{
    background:url('./../images/footer-bg.svg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 800px;
    position: relative;
    box-sizing: border-box;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
.footer .subFooter{
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
    padding: 20px;
    box-sizing: border-box;
}
.footer .subFooter img{
    width:20%;
}
.footer .subFooter span{
    color: #fff;
    padding: 20px;
    text-align: center;
    line-height: 22px;
}
.footer .subFooter span:nth-child(2){
    width: 100%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}


@media (max-width:576px){
    .footer{
        height: 500px;
    }
    .footer .subFooter{
        height: auto;
    }
    .footer .subFooter span{
        color: #fff;
        text-align: center;
        line-height: 22px;
        font-size: 12px;
    }
    .footer .subFooter img{
        width:50%;
    }
}