.aboutMe p{
    font-size: 22px;
    font-weight: 500;
    padding: 20px 0;
}
@media (max-width:576px) {
    .aboutMe p{
        font-size: 18px;
        font-weight: 500;
        padding: 10px;
    }
}