.getquot{
    width: 100%;   
    text-align: center;
    margin: 50px 0;
}
.getquot h2{
    font-weight: 700;
}
.getquot p{
    font-size: 22px;
    font-weight: 500;
    margin-top: 20px;
}