.bedcurm{
    width: 100%;
    padding: 20px 0;
    display: flex;
    justify-content: flex-start;
}

.bedcurm ul{
    width: 50%;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
}
.bedcurm ul li{
    color: var(--text);
    position: relative;
}
.bedcurm ul li.active:after{
    content: '/';
    position: absolute;
    top: 0;
    left: -10px;
    color:var(--text);
}
.bedcurm ul li.active{
    color: #000;
    font-weight: 500;
    margin-left: 15px;
}
.bedcurm h2{
    font-weight: 700;
}