.customOrder{
    width: 100%;
    height: 600px;
    position: relative;
    background:url('./../images/uploads/custom.jpg');
    background-position: center;
    background-size: cover;
    margin-bottom: 50px;
}
.customOrder .customOrderOverLay{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.customOrder .customOrderOverLay h2{
    color: #fff;
    font-weight: 700;
}  
.customOrder .customOrderOverLay button{
    width: 120px;
    background-color: rgba(255, 255, 255, 0.5) !important;
    border: 2px solid #fff;
    padding: 5px 30px !important;
    box-sizing: border-box;
}
.customOrder .customOrderOverLay button svg{
    font-size:25px;
}