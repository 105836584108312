.cardItem{
    border: 0px !important;
    overflow: hidden !important;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.card-img-top{
    width: 100%;
    height: 350px;
    object-fit: cover;


}
.cardItem .card-title{
    font-size: 14px;
    font-weight: 600;
    text-decoration: underline;
    display: flex;
    justify-content: space-between;
} 

.cardItem .card-title svg{
    color: #EB9A19;
}
.cardItem .card-body{
    padding: 10px !important;
}

.cardItem .card-text{
    line-height: 20px;
}

.cardItem .card-text span{
    font-size: 14px;
    line-height: 20px !important;
    text-decoration: underline;
}
.cardItem .card-text h5{
    font-weight: 700;
}