.itemslider {
  padding-bottom: 30px;
  margin-bottom: 30px;
  box-sizing: border-box;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  width: 15px !important;
  height: 15px !important;
  border-radius: 100px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
  overflow: hidden;
}
.slick-dots li.slick-active {
  width: 18px !important;
  height: 18px !important;
}
.slick-dots li.slick-active button {
  background: #000 !important;
}
.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 100% !important;
  height: 100% !important;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: var(--text) !important;
}
.slick-dots li button:before {
  color: transparent !important;
}
