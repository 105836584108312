.contactUs{
    width: 100%;
    height: 600px;
    background-color: #fff;
    padding: 100px;
    box-sizing: border-box;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.contactUs .contactForm h2{
    font-weight: 700;
}

.contactUs .contactForm span{
    font-weight: 400;
    font-size: 14px;
}

.contactUs .contactForm label{
    font-weight: 500;
    font-size: 12px;
    margin-bottom:0 !important;
}
.contactUs .contactForm .form-control::placeholder{
    font-weight: 500;
    font-size: 12px;
}

.contactUs .imgDivContact{
    width: 80%;
    margin: 0 auto;
    height: 100%;
    background: url('./../images/uploads/contactus.jpeg');
    background-size: cover;
    background-position: center;
}

@media (max-width:576px) {
    .contactUs{
        height: auto;
        padding: 30px;
    }
    .contactUs .imgDivContact{
        height: 400px;
        width: 100%;
        margin: 30px 0;
    }
    .contactUs .last{
        order: 1 !important;
    }
    
}