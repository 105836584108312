@import url("variables.css");
* {
  margin: 0;
  padding: 0;
  font-family: var(--fontFamily);
}
body {
  background-color: #f2f2f2;
}
.wrap {
  overflow: hidden;
}
@import url("header.css");
@import url("bedcurm.css");
@import url("card.css");
@import url("slider.css");
@import url("customorder.css");
@import url("getquot.css");
@import url("gallary.css");
@import url("contactus.css");
@import url("aboutme.css");
@import url("footer.css");
@import url("vedio.css");
